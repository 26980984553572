<template>
	<div class="main">
	<v-layout class="import-workers-page-container">
		<!-- Attaching files view ; state: BEFORE_UPLOAD -->
		<v-layout v-if="pageState == pageStates.BEFORE_UPLOAD" class="d-flex flex-column">
			<!-- main page content -->
			<v-layout class="align-self-center align-content-center pt-5 attach-files-container d-flex flex-column" v-cloak @drop.prevent="dragFile" @dragover.prevent>
				<div class="attach-files-input-header"> {{ pageMessages.BEFORE_UPLOAD.attachmentsPanelHeader }} </div>

				<!-- attaching files input : "LOAD" button -->
				<div class="attach-files-input mt-10 d-flex flex-column justify-content-center" style="width: 100%;">
					<!-- options for processing files in zip -->
					<div class="d-flex justify-content-center mt-5" style="flex-wrap: wrap;">
						<!-- omit files headers while processing option -->
						<v-checkbox
							@change="omitHeadersCheckboxUpdated"
							v-model="attachmentOptions.omitHeaders.value"
							:label="attachmentOptions.omitHeaders.label"
							class="attachments-process-option"
							style="padding-top: 17px;"
						></v-checkbox>
						<!-- override existing files option -->
						<!-- <v-checkbox
							@change="overrideExistingFilesCheckboxUpdated"
							v-model="attachmentOptions.overrideExistingFiles.value"
							:label="attachmentOptions.overrideExistingFiles.label"
							class="attachments-process-option"
							style="padding-top: 17px;"
						></v-checkbox> -->
						<!-- upload files with document type option -->
						<!-- <v-checkbox
							@change="uploadWithDocumentTypeCheckboxUpdated"
							v-model="attachmentOptions.uploadWithDocumentType.value"
							:label="attachmentOptions.uploadWithDocumentType.label"
							class="attachments-process-option"
							style="padding-top: 17px;"
						></v-checkbox> -->
						<!-- default type select -->
						<!-- <v-select
							v-model="defaultDocumentType"
							class="attachments-process-option-select"
							:items="documentTypesSelect"
							:label="lviews.defaultDocumentType"
						></v-select> -->
					</div>

					<!-- proper load files -->
					<v-layout class="d-flex flex-column">
						<v-icon class="mt-10" size="70">mdi-file-upload</v-icon>

						<!-- hidden input for files -->
						<input type="file" accept=".xls, .xlsx" ref="uploadFilesPackagesInput" @change="updateDataTable" style="display: none;">

						<v-btn small color="primary mt-5 align-self-center" style="width: 200px;" @click="openInput"> {{ pageMessages.BEFORE_UPLOAD.loadFiles }} </v-btn>
						<span class="mt-5"> {{ pageMessages.BEFORE_UPLOAD.filesSizeInfo }} </span>
					</v-layout>
				</div>
			</v-layout>

			<!-- information for user -->
			<div class="pt-5 pb-5">
				<strong style="color: red;"> {{ pageMessages.BEFORE_UPLOAD.userInfoPrefix }} </strong>
				{{ pageMessages.BEFORE_UPLOAD.userInfoWithoutLinkPart }} 
				<a href="/static/img/worker_documents/import.zip" download>{{ pageMessages.BEFORE_UPLOAD.userInfoLink }}</a>
			</div>
		</v-layout>

		<v-layout v-if="pageState == pageStates.FILE_VALIDATED" class="d-flex flex-column">
			<div class="zip-validated-page d-flex flex-column">
				<!-- after zip validation page : Header -->
				<div class="zip-validated-page-header">
					<div class="zip-validated-page-header-part col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">{{ pageMessages.FILE_VALIDATED.uploadedZip }} <strong>{{ uploadedExcelName }}</strong></div>
					<div class="zip-validated-page-header-part col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
						<div style="float: left; padding-right: 5px;">{{ pageMessages.FILE_VALIDATED.workersToAdd.label }}</div>
						<div style="color: green; float: left; padding-right: 5px;">{{ pageMessages.FILE_VALIDATED.workersToAdd.number }}</div>
						<!-- <div style="color: green; float: left;">{{ pageMessages.FILE_VALIDATED.workersToAdd.suffix }}</div> -->
					</div>
				</div>

				<!-- after zip validation page : Filters -->
				<div class="zip-validated-page-header">
					<div class="zip-validated-page-header-part col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-6"> {{ pageMessages.FILE_VALIDATED.filtersHeader }}: </div>
					<div class="zip-validated-page-header-part col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-6">
						<!-- omit files headers while processing option -->
						<div style="color: green; float: left; padding-right: 5px;">
							<v-checkbox
								v-model="attachmentOptions.omitHeaders.value"
								:label="attachmentOptions.omitHeaders.label"
								class="attachments-process-option pt-0"
								disabled
							></v-checkbox>
						</div>
						<!-- <div style="color: green; float: left; padding-right: 5px;"> -->
							<!-- override existing files option -->
							<!-- <v-checkbox
								v-model="attachmentOptions.overrideExistingFiles.value"
								:label="attachmentOptions.overrideExistingFiles.label"
								class="attachments-process-option"
								disabled
								style="padding-top: 17px;"
							></v-checkbox>
						</div>-->
					</div>
				</div>

				<!-- after zip validation page : Table view -->
				<v-layout class="imported-workers-table d-flex flex-column">
					<!-- Imported documents table view : sorters 
					<div class="imported-workers-table-sorters"> -->
						<!-- filter icon -->
						<!-- <v-flex xs1 style="height: 100%;">
							<v-icon style="color: green; height: 100%; margin-left: 5px; padding-top: auto; padding-bottom: auto;" @click="filterButtonClickHandler">mdi-filter-variant</v-icon>
						</v-flex>
					</div>-->
					
					<v-data-table
						dense
						:headers="validatedWorkersDataHeaders"
						:items="renderedWorkersData"
						item-key="name"
						class="elevation-1"
						:item-class="getDataTableRowClass"
						:search="filters.searchValue"
						:custom-filter="filterItems"
					>
						<template v-slot:top>
							<v-flex xs12 style="display: flex;">
								<v-text-field
									v-model="filters.searchValue"
									:label="pageMessages.FILE_VALIDATED.search"
									class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-10"
								></v-text-field>
								<v-select
									:items="workerStatesSelect"
									:label="pageMessages.FILE_VALIDATED.status"
									v-model="filters.statusOptions"
									@change="filterDocumentsByImportStatus"
									multiple
									class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-10"
								></v-select>
							</v-flex>
						</template>

						<template v-slot:[`item.id`]="{ item }">
							<div v-if="item.importStatus == workerStates.ERROR" style="color: red;"> {{ item.id}}</div>
							<div v-else> {{ item.id }} </div>
						</template>

						<template v-slot:[`item.lastName`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.lastName}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.lastName}}</div>
						</template>

						<template v-slot:[`item.firstName`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.firstName}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.firstName}}</div>
						</template>

						<template v-slot:[`item.externalID`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.externalID}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.externalID}}</div>
						</template>

						<template v-slot:[`item.email`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.email}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.email}}</div>
						</template>

						<template v-slot:[`item.status`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.status}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.status}}</div>
						</template>

						<template v-slot:[`item.projects`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.projects}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.projects}}</div>
						</template>

						<template v-slot:[`item.cards`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.cards}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.cards}}</div>
						</template>

						<template v-slot:[`item.tags`]="{ item }">
							<div v-if="item.importStatus != workerStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'">{{ item.tags}}</div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.tags}}</div>
						</template>

						<template v-slot:[`item.importStatus`]="{ item }">
							<v-icon v-if="item.importStatus == workerStates.OK" style="color: green;">mdi-checkbox-marked-circle</v-icon>
							<v-icon v-else-if="item.importStatus == workerStates.WARNING" style="color: #FFA500;">mdi-alert-circle</v-icon>
							<v-icon v-else-if="item.importStatus == workerStates.ERROR" style="color: red;">mdi-alert-circle</v-icon>
						</template>

						<template v-slot:[`item.importComment`]="{ item }">
							<div v-if="item.importStatus == workerStates.ERROR" style="color: red; width: 200px;">
								<span v-for="comment in item.importComment" :key="comment">
									{{ comment }}<br> 
								</span>
							</div>
							<div v-else :class="item.deleted?'row-deleted':'row-to-import'">
								<span v-for="comment in item.importComment" :key="comment">
									{{ comment }}<br> 
								</span>
							</div>
						</template>

						<template v-slot:[`item.action`]="{item}">
							<v-icon v-if="item.deleted && item.importStatus != workerStates.ERROR" style="color: green;" @click="() => { updateImportConcreteWorker(item.id); }"> mdi-backup-restore </v-icon>
							<v-icon v-else-if="!item.deleted && item.importStatus != workerStates.ERROR" style="color: green;" @click="() => { updateImportConcreteWorker(item.id); }"> mdi-delete </v-icon>
						</template>
					</v-data-table>
				</v-layout>

				<!-- import first 100 row error -->
				<div v-if="showRowErrorImport">
					<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 0px; margin-bottom: 0px; color:red;"> {{lviews.rowError}} </div>
					</div>
				</div>

				<!-- import summary header -->
				<div class="zip-validated-page-header-part">
					<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 0px; margin-bottom: 0px; text-decoration: underline;"> {{ lviews.importSummaryHeaders }} </div>
				</div>

				<!-- import summary -->
				<div class="zip-validated-page-header-part">
					<div style="float: left; padding-right: 5px; color: green;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lviews.importWorkersNumber }}: {{ importSummary.importWorkersNumber }}
					</div>
					<div style="float: left; padding-right: 5px;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lviews.excludedWorkersNumber }}: {{ importSummary.excludedWorkersNumber }}
					</div>
					<div style="float: left; padding-right: 5px; color: red;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lviews.errorWorkersNumber }}: {{ importSummary.errorWorkersNumber }}
					</div>
					<!-- <div style="float: left; padding-right: 5px; color: #ff4c00;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lviews.warningDocumentsNumber }}: {{ importSummary.warningDocumentsNumber }}
					</div> -->
				</div>

				<!-- Bottom buttons -->
				<!-- Case when no error was detected while zip validation -->
				<div class="zip-validated-page-footer" style="float: left;" v-if="!errorInDataOccurred">
					<v-btn class="mr-3" @click="cancel"> {{ pageMessages.FILE_VALIDATED.cancelButtonLabel }} </v-btn>
					<v-btn width="200px" :disabled="importSummary.importWorkersNumber == 0" class="ml-3" color="primary" @click="importWorkers"> {{ pageMessages.FILE_VALIDATED.addWorkersButtonLabel }} </v-btn>
				</div>
				<!-- Case when any issue was found -->
				<div class="zip-validated-page-footer" v-else>
					<v-flex xs12>
						<div>
							<v-btn class="mr-3" @click="cancel"> {{ pageMessages.FILE_VALIDATED.cancelButtonLabel }} </v-btn>
							<v-btn width="200px" :disabled="importSummary.importWorkersNumber == 0" class="ml-3" color="primary" @click="importWorkers"> {{ pageMessages.FILE_VALIDATED.addWorkersButtonLabel }} </v-btn>
						</div>
						<!-- <div style="width: 100%; text-align: center; color: red; float: left; margin-top: 15px;">{{ pageMessages.FILE_VALIDATED.uploadWithoutErrorsMessage }}</div> -->
					</v-flex>
				</div>
			</div>
		</v-layout>

		<v-layout v-if="pageState == pageStates.FILES_UPLOADING" class="d-flex flex-column">
			<v-container style="height: 550px;">
				<v-row
					class="fill-height"
					align-content="center"
					justify="center"
				>
					<v-col>
						<v-progress-linear
							:value="uploadingProgressBar.uploadProgress"
							color="primary"
							height="25"
							:indeterminate="uploadingProgressBar.indeterminate"
						>
							<template v-if="!uploadingProgressBar.indeterminate" v-slot:default="{ value }">
								<strong style="color:#FFFFFF">{{ Math.ceil(value) }}%</strong>
							</template>
						</v-progress-linear>
					</v-col>
					<v-col
						class="text-subtitle-1 text-center"
						cols="12"
					>
						{{ uploadingProgressBar.message }}
					</v-col>
				</v-row>
			</v-container>
		</v-layout>

		<v-layout v-if="pageState == pageStates.VALIDATION_ERROR" class="d-flex flex-column">
			<!-- main page content -->
			<v-layout class="align-self-center align-content-center pt-2 attach-files-container d-flex flex-column">
				<!-- attaching files input : "LOAD" button -->
				<div class="attach-files-input mt-10 d-flex flex-column justify-content-center" style="width: 100%;">
					<!-- options for processing files in zip -->
					<div class="d-flex justify-content-center align-self-center mt-5" style="font-size: 20px; font-weight: 500; color: red;">
						{{ pageZipErrorMessageHeader }}
					</div>

					<!-- proper load files -->
					<v-layout class="d-flex flex-column">
						<v-icon class="mt-10 align-self-center" size="80" style="color: red; background-color: white; border: 2px solid red; border-radius: 50%; width: 85px;">mdi-exclamation</v-icon>

						<div class="d-flex justify-content-center align-self-center mt-5" style="font-size: 25px;">
							{{ pageZipErrorMessage }}
						</div>
						<span class="mt-5"> {{ pageMessages.VALIDATION.correctFiles }} </span>

						<v-btn small color="grey lighten-2 mt-5 align-self-center" style="width: 200px;" @click="cancel"> {{ pageMessages.VALIDATION.goBack }} </v-btn>
					</v-layout>
				</div>
			</v-layout>

			<!-- information for user -->
			<div class="pt-5">
				<strong style="color: red;"> {{ pageMessages.BEFORE_UPLOAD.userInfoPrefix }} </strong>
				{{ pageMessages.BEFORE_UPLOAD.userInfoWithoutLinkPart }} <span @click="showImportTemplateDialog = true" style="border-bottom: 1px solid blue; color: blue; cursor: pointer;">{{ pageMessages.BEFORE_UPLOAD.userInfoLink }}</span>
				<!-- <v-btn text flat to="/">{{ pageMessages.BEFORE_UPLOAD.downloadTemplate }}</v-btn> -->
			</div>
		</v-layout>

		<!-- after upload view : no errors -->
		<v-layout v-if="pageState == pageStates.WORKERS_UPLOADED_CORRECTLY" class="d-flex flex-column">
			<v-layout class="align-self-center align-content-center pt-10 attach-files-container d-flex flex-column">
				<v-icon style="margin-top: 70px;" size="80" color="primary">mdi-checkbox-marked-circle</v-icon>
				<span class="align-self-center"> {{ pageMessages.FILES_UPLOADED.globalMessage }} </span>
				<!-- <v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" v-if="summaryUuid != null" @click="downloadSummaryExcelFile"> {{ lviews.downloadImportSummary }} </v-btn> -->
				<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" @click="cancel"> {{ lviews.validatedZip.goBack }} </v-btn>
			</v-layout>
		</v-layout>

		<!-- after upload view : some errors while uploading -->
		<v-layout v-if="pageState == pageStates.WORKERS_UPLOADED_WITH_ERRORS" class="d-flex flex-column">
			<div class="zip-validated-page d-flex flex-column">
				<!-- after zip validation page : Table view -->
				<v-layout class="imported-workers-table d-flex flex-column" style="margin-top:50px; padding: 0px;">                    
					<v-data-table
						dense
						:headers="unsavedFilesFromUploadHeaders"
						:items="unsavedFilesFromUpload"
						item-key="name"
						class="elevation-1"
					>
						<template v-slot:top>
							<v-flex xs12 style="margin-left: 15px; margin-top: 10px; margin-bottom: 10px; color: red;">
								{{ lviews.filesUploadedButSomeErrorsOccurred }}
							</v-flex>
						</template>
					</v-data-table>
					<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" v-if="summaryUuid != null" @click="downloadSummaryExcelFile"> {{ lviews.downloadImportSummary }} </v-btn>
					<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" @click="cancel"> {{ lviews.validatedZip.goBack }} </v-btn>
				</v-layout>
			</div>
		</v-layout>

		<!-- after upload view : invalid file uploaded - not zip file -->
		<v-layout v-if="pageState == pageStates.INVALID_INPUT_FILE" class="d-flex flex-column">
			<v-layout class="align-self-center align-content-center pt-10 attach-files-container d-flex flex-column">
				<v-icon style="margin-top: 70px; width: 84px; margin-left: auto; margin-right: auto; border-radius: 50%; border: 2px solid red;" size="80" color="red">mdi-exclamation</v-icon>
				<span class="align-self-center"> {{ pageMessages.INVALID_INPUT_FILE.globalMessage }} </span>
				<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" @click="cancel"> {{ lviews.validatedZip.goBack }} </v-btn>
			</v-layout>
		</v-layout>
	</v-layout>
</div>
</template>

<script>
// import JSZip from "jszip";
import axios from 'axios';
import appConfig from '@/app_config'

export default {
	data: function() {
		return {
			/**
			 * Whole page states:
			 * BEFORE_UPLOAD  - before any action is being processed; this state is created for uploading zip package
			 * ZIP_VALIDATION - this state means that page sent request with attached zip package to server and is waiting for
			 * FILE_VALIDATED  - this state means that page received response from server after validation
			 */
			pageStates: {
				BEFORE_UPLOAD: 0,
				FILE_VALIDATED: 1,
				VALIDATION_ERROR: 2,
				WORKERS_UPLOADED_CORRECTLY: 3,
				WORKERS_UPLOADED_WITH_ERRORS: 4,
				INVALID_INPUT_FILE: 5,
				FILES_UPLOADING: 6
			},

			// attachment options for files in zip -> omit headers, override existing files etc.
			attachmentOptions: {
				omitHeaders: {
					value: false,
					label: ""
				},
				overrideExistingFiles: {
					value: false,
					label: ""
				},
				uploadWithDocumentType: {
					value: false,
					label: ""
				},
			},

			// enums for worker states after validation
			workerStates: {
				OK: 0,
				WARNING: 1,
				ERROR: 2
			},
			workerStatesSelect: [
				{
					text: "",
					value: 0,
				},
				{
					text: "",
					value: 1,
				},
				{
					text: "",
					value: 2
				}
			],

			// all messages that are rendered on page are kept in one place
			pageMessages: {
				BEFORE_UPLOAD: {
					userInfoPrefix: "",
					userInfo: "",
					attachmentsPanelHeader: "",
					loadFiles: "",
					filesSizeInfo: "",
				},
				VALIDATION: {
					overlayerMessage: "",
					excelLackMessage: "",
					tooLargeExcelLoadedMessage: "",
					invalidZipStructure: "",
					correctFiles: "",
					goBack: "",
				},
				FILE_VALIDATED: {
					uploadedZip: "",
					workersToAdd: {
						label: "",
						number: 1,
						suffix: "{L dokument }"
					},
					selectFilesStatuses: {
						label: "",
						items: []
					},
					filtersHeader: "",
					uploadWithoutErrorsMessage: "",
					goBack: "",
					search: "",
					status: "",
				},
				FILES_UPLOADED: {
					globalMessage: "",
				},
				INVALID_INPUT_FILE: {
					globalMessage: "",
				},
			},

			// current page state -> it's initiated in "mounted" function
			pageState: null,
			pageZipErrorMessageHeader: null,
			pageZipErrorMessage: null,

			// uploaded ZIP data
			uploadedExcelName: "",
			uploadedZipContent: null,
			uploadedExcelContentSize: null,
			uploadedZip: null,

			uploadingProgressBar: {
				uploadProgress: 0,
				indeterminate: true,
				message: ""
			},

			// validated file information after zip validation
			shownPage: 1,
			validatedWorkersDataHeaders:[
				{
					text: 'Nr',
					align: 'start',
					sortable: true,
					value: 'id',
				},
				{ text: '', value: 'lastName' },
				{ text: '', value: 'firstName' },
				{ text: '', value: 'externalID' },
				{ text: '', value: 'email' },
				{ text: '', value: 'status' },
				{ text: '', value: 'projects', sortable: false },
				{ text: '', value: 'cards', sortable: false },
				{ text: '', value: 'tags', sortable: false },
				{ text: '', value: 'importStatus' },
				{ text: '', value: 'importComment' },
				{ text: '', value: 'action', sortable: false },
			],
			validatedWorkersData: [
				/**
				 * {
				 *      id: {Number},
				 *      lastName: {String},
				 *      firstName: {String} or {null},
				 *      externalID: {String},
				 * 		email: {String},	
				 * 		projects: {String},
				 * 		cards: {String},
				 * 		tags: {String},
				 *      status: {Number},
				 * 		importStatus: {Number}
				 * 		importComment
				 *      comment: {String},
				 *      action: "",
				 *      deleted: {Boolean},
				 *      visible: {Boolean},
				 * }
				 */
			],
			renderedWorkersData: [
				/**
				 * {
				 *      id: {Number},
				 *      lastName: {String},
				 *      firstName: {String} or {null},
				 *      externalID: {String},
				 * 		email: {String},	
				 * 		projects: {String},
				 * 		cards: {String},
				 * 		tags: {String},
				 *      status: {Number},
				 *      comment: {String},
				 * 		importStatus: {Number}
				 * 		importComment
				 *      action: "",
				 *      deleted: {Boolean},
				 *      visible: {Boolean},
				 * }
				 */
			],

			dataToImport: null,

			errorInDataOccurred: false,

			filters: {
				searchValue: "",
				statusOptions: []
			},

			workersData: [
				/**
				 * {
				 *      pesel: {String},
				 *      fullname: {String} or null,
				 *      workerId: {Number} or null,
				 * }
				 */
			],

			unsavedFilesFromUpload: [],
			unsavedFilesFromUploadHeaders: [
				{ text: '', align: 'start', sortable: false, value: 'name' },
				{ text: '', sortable: false, value: 'error' }
			],

			configExcelFile: null,
			showImportTemplateDialog: false,
			showRowErrorImport: false,

			importSummary: {
				importWorkersNumber: null,
				excludedWorkersNumber: null,
				errorWorkersNumber: null,
				warningDocumentsNumber: null
			},

			// defaultDocumentType: null,
			summaryUuid: null,
		}
	},
	methods: {
		dragFile(file){
			// let userPermissions = localStorage.getItem("user_permissions");
			// if (!userPermissions.includes("fob.worker_docs_add")) {
			// 	return;
			// }
			// else{
				let droppedFiles = file.dataTransfer.files;
				if(!droppedFiles) return;
				let fileList = [];
				for (var i=0; i<droppedFiles.length; i++){
					fileList.push(droppedFiles[i]);
				}
				let extension = fileList[0].name.split('.').pop()
				if(extension == "xls" || extension == "xlsx"){
					this.updateDataTable(fileList[0])
					
				}
				else{
					console.log("ERROR")
					// TODO: Catch and show incorrect file extension error
				}
			// }

		},
		// downloadSummaryExcelFile: async function() {
		// 	if (this.summaryUuid == null || this.summaryUuid == undefined) {
		// 		this.$emit('set-state', 'default');
		// 		this.$emit('display-error', this.lmessages.errorOccurredTitle, this.lviews.couldNotDownloadSummaryFile);
		// 		return;
		// 	}
		// 	this.$emit('set-state', "LOADING_OVERLAYER");
		// 	var xhr = new XMLHttpRequest();
		// 	var that = this;
		// 	xhr.onload = function() {
		// 		that.$emit('set-state', 'default');
		// 		var reader = new FileReader();
		// 		reader.onloadend = function() {
		// 			const linkSource = reader.result;
		// 			const downloadLink = document.createElement('a');

		// 			document.body.appendChild(downloadLink);
		// 			downloadLink.href = linkSource;
		// 			downloadLink.target = '_self';
		// 			downloadLink.download = "import_" + that.summaryUuid + ".xls";
		// 			downloadLink.click();
		// 		}

		// 		if (xhr.status < 400) {
		// 			console.log(xhr.response);
		// 			reader.readAsDataURL(xhr.response);
		// 		}
		// 		else {
		// 			that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lviews.couldNotDownloadSummaryFile);   
		// 		}
		// 	};
		// 	xhr.onerror = function(){
		// 		that.$emit('set-state', 'default');
		// 		that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lviews.couldNotDownloadSummaryFile);
		// 	};
		// 	xhr.open('GET', appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v2/worker-documents/import-summary/" + this.summaryUuid, true);
		// 	xhr.responseType = 'blob';
		// 	xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
		// 	xhr.send();
		// },

		omitHeadersCheckboxUpdated(newValue){
			if (newValue)
				localStorage.setItem("omitHeadersCheckbox", 1);
			else
				localStorage.setItem("omitHeadersCheckbox", 0);
		},
		// overrideExistingFilesCheckboxUpdated(newValue){
		// 	if (newValue)
		// 		localStorage.setItem("overrideExistingFilesCheckbox", 1);
		// 	else
		// 		localStorage.setItem("overrideExistingFilesCheckbox", 0);
		// },

		// Opens file input so that user can choose zip to upload
		openInput: function(){
			this.$refs.uploadFilesPackagesInput.click();
		},

		assignRenderedWorkersData: function(){
			this.renderedWorkersData = [];
			for(var i = 0; i < this.validatedWorkersData.length; i++){
				this.renderedWorkersData.push(this.validatedWorkersData[i]);
			}
		},

		filterDocumentsByImportStatus: function(){
			if (this.filters.statusOptions.length == 0){
				this.assignRenderedWorkersData();
				return;
			}

			this.renderedWorkersData = [];
			for(var i = 0; i < this.validatedWorkersData.length; i++){
				let shouldBeRendered = (this.filters.statusOptions.find(
					element => { return element == this.validatedWorkersData[i].importStatus; }
				) != undefined);
				if (shouldBeRendered){
					this.renderedWorkersData.push(this.validatedWorkersData[i]);
				}
			}
		},


		

		// reads loaded zip file
		// readZipContent: async function(zippedFile){   
		// 	var zipContent = null;
		// 	var that = this;
		// 	try{
		// 		zipContent = await JSZip.loadAsync(zippedFile, {
		// 			decodeFileName: function (bytes) {
		// 				let filename = that.assignSpecialCharacters(bytes);
		// 				return filename;
		// 			}
		// 		});
		// 	}catch(error){
		// 		console.log(error);
		// 	}
		// 	return zipContent;
		// },

		/**
		 * Extracts *.xls(x) file from given zip content
		 * @param zipContent - zip content returned by method "readZipContent"
		 * @returns excelFile - extracted excel file 
		 */
		// getExcelFile: async function(zipContent){
		// 	var excelFile;
		// 	zipContent.forEach((relativePath, zipEntry) => {
		// 		let matches = (zipEntry.name.search(".xls") != -1);
		// 		if (matches){
		// 			excelFile = zipEntry;
		// 		}
		// 	});

		// 	let binaryExcel = await excelFile.async("blob");
		// 	let excelWorkbook = new ExcelFile();
		// 	await excelWorkbook.loadWorkbook(binaryExcel);
		// 	return excelWorkbook;
		// },


		/**
		 * Validates document type according to downloaded document types list.
		 * @param type - {String} type we want to validate
		 * @returns {Object} (with structure presented below) if given type is correct, otherwise returns undefined
		 * 
		 * {
		 *      id : {String},  // identifier for concrete document type
		 *      value: {String} // value for concrete document type 
		 * }
		 */
		// validateDocumentType: function(type){
		// 	if (type == undefined) {
		// 		return undefined;
		// 	}

		// 	let uppercaseType = type.toLocaleUpperCase();
		// 	for(var i = 0; i < this.documentTypesList.length; i++){
		// 		if (uppercaseType == this.documentTypesList[i].id || uppercaseType == this.documentTypesList[i].value.toLocaleUpperCase())
		// 			return this.documentTypesList[i];
		// 	}
		// 	return undefined;
		// },

		/**
		 * Gets worker fullname
		 * @param pesel - {String} pesel (from given excel) of worker, whose fullname we want know 
		 * @returns {Object} when worker is found in workersData by his/her pesel number
		 * @returns {undefined} when worker is not found by his/her pesel number
		 */
		// getDocumentWorker: function(pesel) {
		// 	for(var i = 0; i < this.workersData.length; i++){
		// 		if (pesel == this.workersData[i].pesel){
		// 			return this.workersData[i];
		// 		}
		// 	}
		// 	return undefined;
		// },

		/**
		 * Validates single entry of uploaded zip
		 * @param relativePath - entry path in zip
		 * @param zipEntry - entry to validate
		 * @param excelFile - file necessary to validation received from "getExcelFile" method
		 * @param rowId - number of row with which we want to validate given entry
		 * @returns Object with structure presented below
		 * {
		 *      inputFilename: {String},
		 *      worker: {String},
		 *      outputFilename: {String},
		 *      status: {one value from enum "pageStates"},
		 *      comment: {String}
		 * }
		 */
		// validateEntry: async function(zipEntry, excelFile, rowId){
		// 	let entryNameSplit = zipEntry.name.split('/');
		// 	let entryName = entryNameSplit[entryNameSplit.length - 1];
		// 	let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
		// 	let extensionTypes = ['.TXT', '.DOC', '.DOCX', '.DOCM', '.ODT', '.PDF', '.CSV', '.XLS', '.XLSX', '.XLSM', '.ODS', '.PPS', '.PPT', '.PPSX', '.PPTX', '.POTX', '.ODP', '.JPEG', '.JPG', '.PNG', '.BMP', '.ICO', '.SVG', '.WEBP', '.GIF', '.PSD', '.HEIC', '.NEF', '.CRW', '.AI', '.ID', '.ZIP', '.RAR', '.RAR5', '.7Z'];

		// 	let index = rowId;//this.getFileIndexInExcel(excelFile, entryName);
		// 	// document found in excel
		// 	if (index != -1) {
		// 		var finalEntryValidData = {
		// 			documentType: undefined,
		// 			entryInputFilename: "",
		// 			entryOutputFilename: "",
		// 			peselNumber: "",
		// 			workerId: null,
		// 			workerFullname: "",

		// 			warnings: [],
		// 			errors: []
		// 		}


		// 		if(zipEntry._data instanceof Promise){
		// 			if (zipEntry._data.PromiseResult != "fulfilled") {
		// 				await zipEntry._data;
		// 			}
		// 			// If still remains a promise, the file is empty (promise result is empty)
		// 			if(zipEntry._data instanceof Promise){
		// 				finalEntryValidData.errors.push(this.lviews.validation.fileEmpty);
		// 			}
		// 		}

		// 		if (this.attachmentOptions.uploadWithDocumentType.value) {
		// 			var docType;
		// 			try {
		// 				docType = excelFile.sheet["D" + index.toString()].v;
		// 			} catch(error) {
		// 				docType = undefined;
		// 			}
		// 			let docTypeObj = this.validateDocumentType(docType);
		// 			if (docTypeObj != undefined) {
		// 				finalEntryValidData.documentType = docTypeObj;
		// 			} else {
		// 				let defaultDocumentTypeFound = this.documentTypesSelect.find(x => { return x.value == this.defaultDocumentType; });

		// 				finalEntryValidData.documentType = {
		// 					id: defaultDocumentTypeFound.value,
		// 					value: defaultDocumentTypeFound.text
		// 				}
		// 				let message = this.lviews.validation.invalidDocTypeWarningMessage.replace("{typeInExcel}", docType==undefined?"''":docType);
		// 				message = message.replace("{defaultType}", defaultDocumentTypeFound.text);
		// 				finalEntryValidData.warnings.push(message);
		// 			}
		// 		}
		// 		else {
		// 			let defaultDocumentTypeFound = this.documentTypesSelect.find(x => { return x.value == this.defaultDocumentType; });

		// 			finalEntryValidData.documentType = {
		// 				id: defaultDocumentTypeFound.value,
		// 				value: defaultDocumentTypeFound.text
		// 			}
		// 		}

		// 		try {
		// 			finalEntryValidData.entryInputFilename = excelFile.sheet["A" + index.toString()].v;

		// 			// check extension
		// 			let fileInputExtension = (finalEntryValidData.entryInputFilename).match(patternFileExtension);
					
		// 			if (fileInputExtension == null) {
		// 				finalEntryValidData.entryInputFilename += '.pdf';
		// 			} else {
		// 				if (!extensionTypes.includes(fileInputExtension[0].toUpperCase())) {
		// 					finalEntryValidData.entryInputFilename += '.pdf';
		// 				}
		// 			}

		// 		} catch(error) {
		// 			finalEntryValidData.entryInputFilename = "";
		// 			finalEntryValidData.errors.push(this.lviews.validation.errorOccurredWhileReadingInputFilename);
		// 		}

		// 		try {
		// 			let entryOutputFilename = excelFile.sheet["C" + index.toString()]

		// 			if (entryOutputFilename == undefined || entryOutputFilename == null) {
		// 				finalEntryValidData.entryOutputFilename = finalEntryValidData.entryInputFilename;
		// 			} else {
		// 				finalEntryValidData.entryOutputFilename = entryOutputFilename.v;

		// 				// check if outfile name don't have a extension add extension from input file
		// 				let fileOutputExtension = (finalEntryValidData.entryOutputFilename).match(patternFileExtension);
		// 				let fileInputExtension = (finalEntryValidData.entryInputFilename).match(patternFileExtension);

		// 				if (finalEntryValidData.entryInputFilename != "") {
		// 					if (fileOutputExtension == null) {
		// 						finalEntryValidData.entryOutputFilename += fileInputExtension[0];
		// 					} 
		// 					else {
		// 						if (!extensionTypes.includes(fileOutputExtension[0].toUpperCase())) {
		// 							finalEntryValidData.entryOutputFilename += fileInputExtension[0];
		// 						} 
		// 					}
		// 				} 
		// 			}           
		// 		} catch (error) {
		// 			finalEntryValidData.entryOutputFilename = "";
		// 			finalEntryValidData.errors.push(this.lviews.validation.errorOccurredWhileReadingOutputFilename);
		// 		}

		// 		try {
		// 			finalEntryValidData.peselNumber = String(excelFile.sheet["B" + index.toString()].v);
		// 		} catch (error) {
		// 			finalEntryValidData.peselNumber = undefined;
		// 		}
		// 		let workerObj = this.getDocumentWorker(finalEntryValidData.peselNumber);
		// 		if (workerObj != undefined) {
		// 			if (workerObj.workerId == null){
		// 				finalEntryValidData.workerId = null;
		// 				finalEntryValidData.workerFullname = "";
		// 				finalEntryValidData.errors.push(this.lviews.validation.unknownUserIncorrectPesel);
		// 			}
		// 			else {
		// 				if (workerObj.workerDocumentsNames.includes(finalEntryValidData.entryOutputFilename)) {
		// 					if (this.attachmentOptions.overrideExistingFiles.value) {
		// 						finalEntryValidData.warnings.push(this.lviews.validation.fileWillBeOverwritten);
		// 					}
		// 					else {
		// 						finalEntryValidData.warnings.push(this.lviews.validation.duplicateFilename);
		// 					}
		// 				}

		// 				finalEntryValidData.workerId = workerObj.workerId;
		// 				finalEntryValidData.workerFullname = workerObj.fullname;
		// 			}
		// 		} else {
		// 			finalEntryValidData.workerId = null;
		// 			finalEntryValidData.workerFullname = "";
		// 			finalEntryValidData.errors.push(this.lviews.validation.unknownUserIncorrectPesel);
		// 		}

		// 		if (finalEntryValidData.errors.length > 0) {
		// 			let errorsMessage = finalEntryValidData.errors.join("|");
		// 			return {
		// 				inputFilename: finalEntryValidData.entryInputFilename,
		// 				worker: finalEntryValidData.workerFullname,
		// 				workerId: finalEntryValidData.workerId,
		// 				outputFilename: finalEntryValidData.entryOutputFilename,
		// 				status: this.workerStates.ERROR,
		// 				comment: errorsMessage,
		// 				documentType: finalEntryValidData.documentType,
		// 			}
		// 		}
		// 		if (finalEntryValidData.warnings.length > 0) {
		// 			return {
		// 				inputFilename: finalEntryValidData.entryInputFilename,
		// 				worker: finalEntryValidData.workerFullname,
		// 				workerId: finalEntryValidData.workerId,
		// 				outputFilename: finalEntryValidData.entryOutputFilename,
		// 				status: this.workerStates.WARNING,
		// 				comment: finalEntryValidData.warnings.join(" | "),
		// 				documentType: finalEntryValidData.documentType,
		// 			}
		// 		}
		// 		return {
		// 			inputFilename: finalEntryValidData.entryInputFilename,
		// 			worker: finalEntryValidData.workerFullname,
		// 			workerId: finalEntryValidData.workerId,
		// 			outputFilename: finalEntryValidData.entryOutputFilename,
		// 			status: this.workerStates.OK,
		// 			comment: "OK",
		// 			documentType: finalEntryValidData.documentType,
		// 		}
		// 	}
		// 	else { // document not found in excel -> ERROR
		// 		return {
		// 			inputFilename: entryName,
		// 			worker: "",
		// 			outputFilename: "",
		// 			status: this.workerStates.ERROR,
		// 			comment: this.lviews.validation.fileIsNotPresentInExcel,
		// 			documentType: {
		// 				id: undefined,
		// 				value: ""
		// 			},
		// 		}
		// 	}
		// },

		// getUsersInfoRequestData: function(excelFile){
		// 	var cellValue, pesel_values = [];
		// 	for (var i = 1; i <= excelFile.numberOfRows; i++){
		// 		cellValue = undefined;
		// 		try {
		// 			cellValue = excelFile.sheet["B" + String(i)].v;
		// 		} catch(error) {
		// 			cellValue = undefined;
		// 		}
		// 		if (cellValue != undefined){
		// 			if (!pesel_values.includes( String(cellValue) ) ){
		// 				cellValue = String(cellValue);
		// 				if (cellValue.length < 11){
		// 					for(var k = 0; k < cellValue.length - 11; k++){
		// 						cellValue += '0';
		// 					}
		// 				}
		// 				else if(cellValue.length > 11){
		// 					cellValue = cellValue.substring(0,11);
		// 				}
		// 				pesel_values.push( String(cellValue) );
		// 			}
		// 		}
		// 	}
		// 	return { 
		// 		pesels: pesel_values,
		// 	}
		// },

		// assignWorkersDataAndDocumentTypes: function(response){
		// 	// assign downloaded document types data
		// 	this.documentTypesList = [];
		// 	let documentTypesObjKeys = Object.keys(response.data.document_types);
		// 	for (let i = 0; i < documentTypesObjKeys.length; i++) {
		// 		this.documentTypesList.push({
		// 			id: response.data.document_types[ documentTypesObjKeys[i] ],
		// 			value: documentTypesObjKeys[i]
		// 		});
		// 	}

		// 	let workerDataObjKeys = Object.keys(response.data.worker_data);
		// 	// assign uploaded workers data
		// 	this.workersData = [];
		// 	for (let i = 0; i < workerDataObjKeys.length; i++) {
		// 		if (response.data.worker_data[ workerDataObjKeys[i] ] == null){
		// 			this.workersData.push({
		// 				pesel: String(workerDataObjKeys[i]),
		// 				fullname: null,
		// 				workerId: null,
		// 				workerDocumentsNames: null
		// 			});
		// 		} 
		// 		else {
		// 			this.workersData.push({
		// 				pesel: String(workerDataObjKeys[i]),
		// 				fullname: response.data.worker_data[ workerDataObjKeys[i] ].name,
		// 				workerId: response.data.worker_data[ workerDataObjKeys[i] ].worker_id,
		// 				workerDocumentsNames: response.data.worker_data[ workerDataObjKeys[i] ].documents
		// 			});
		// 		}
		// 	}
		// },

		adaptWorkerData(data){
			let adaptedWorkersData = []
			let dataToImport = {
				workers: []
			}
			let id=1
			for(let key in data){
				let workerData=data[key][0]
				let result ={
					id: id,
					lastName: workerData.last_name,
					firstName: workerData.first_name,
					externalID: workerData.unique_personal_id,
					email: workerData.email,	
					projects: workerData.projects,
					cards: workerData.cards,
					tags: workerData.tags,
					status: "",
					comment: workerData.comment,
					importStatus: this.workerStates.OK,
					importComment: [],
					action: "",
					deleted: false,
					visible: true,
				}
				if(workerData.status==0){
					result.status=this.lviews.active
				}
				else if(workerData.status==1){
					result.status=this.lviews.inactive
				}
				else{
					result.status=this.lviews.unknown
				}
				if(data[key].length>1){
					result.importStatus=this.workerStates.ERROR
					// let errorsArray=[]
					let nullFields=[]
					let invalidProjectsArr=[]
					let invalidTagsArr=[]
					let invalidCardsArr=[]
					for(let i=1; i<data[key].length;i++){
						let error = data[key][i]
						let currentErrorTitle = Object.keys(error)[0]
						let currentErrorList= error[Object.keys(error)[0]]
						let currentErrorMessage=""
						switch(currentErrorTitle){
							case 'project':
								for(let item of currentErrorList){
									invalidProjectsArr.push(item.code)
								}
								break;
							case 'tag':
								for(let item of currentErrorList){
									invalidTagsArr.push(item.code)
								}
								break;
							case 'card':
								for(let item of currentErrorList){
									invalidCardsArr.push(item.code)
								}
								break;
							default: 
								for(let item of currentErrorList){
									if(item.code=='null'){
										nullFields.push(currentErrorTitle)
									}
									else if(item.code=='unique'){
										currentErrorMessage=this.lviews.validation.unique
									}
									else{
										currentErrorMessage=item.string
									}
								}
						}
						if(currentErrorMessage!=""){
							result.importComment.push(currentErrorMessage)
						}					
					}
					if(nullFields.length>0){
						result.importComment.push(this.lviews.validation.fieldCannotBeNull+": "+nullFields.join(", "))
					}
					if(invalidProjectsArr.length>0){
						result.importComment.push(this.lviews.validation.nonExistentProjects+": "+invalidProjectsArr.join(", "))
					}
					if(invalidTagsArr.length>0){
						result.importComment.push(this.lviews.validation.nonExistentTags+": "+invalidTagsArr.join(", "))
					}
					if(invalidCardsArr.length>0){
						result.importComment.push(this.lviews.validation.cardAssigned+": "+invalidCardsArr.join(", "))
					}
					// result.importComment = errorsArray.join('<br>')
				}
				else{
					dataToImport.workers.push(data[key][0])
				}

				adaptedWorkersData.push(result)
				id++
			}
			this.dataToImport = dataToImport
			return adaptedWorkersData
		},

		// getAdaptedValidatedData: async function(excelFile, zipContent){
		// 	let adaptedFilesData = [];
		// 	let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
		// 	let breakFor = true;
		// 	this.showRowErrorImport = false;
		// 	let extensionTypes = ['.TXT', '.DOC', '.DOCX', '.DOCM', '.ODT', '.PDF', '.CSV', '.XLS', '.XLSX', '.XLSM', '.ODS', '.PPS', '.PPT', '.PPSX', '.PPTX', '.POTX', '.ODP', '.JPEG', '.JPG', '.PNG', '.BMP', '.ICO', '.SVG', '.WEBP', '.GIF', '.PSD', '.HEIC', '.NEF', '.CRW', '.AI', '.ID', '.ZIP', '.RAR', '.RAR5', '.7Z'];

		// 	var rowId = 1;
		// 	if (this.attachmentOptions.omitHeaders.value) {
		// 		rowId++;
		// 	}
		// 	while( rowId <= excelFile.numberOfRows ) {
		// 		var inputFilename;
		// 		try {
		// 			inputFilename = excelFile.sheet["A" + rowId.toString()].v;

		// 			//Get the file Extension 
		// 			let fileExtension = (inputFilename).match(patternFileExtension);
					
		// 			if (fileExtension == null) {
		// 				inputFilename += '.pdf'    
		// 			} else {
		// 				if (!extensionTypes.includes(fileExtension[0].toUpperCase())) {
		// 					inputFilename += '.pdf'    
		// 				}
		// 			}
		// 		} catch (error) {
		// 			adaptedFilesData.push({
		// 				id: rowId,
		// 				inputFilename: "",
		// 				workerName: "",
		// 				workerId: null,
		// 				outputFilename: "",
		// 				status: this.workerStates.ERROR,
		// 				comment: this.lviews.inputFilenameNotProvided,
		// 				action: "",
		// 				deleted: false,
		// 				visible: true,
		// 				documentType: null,
		// 			});
		// 		}

		// 		let foundZipEntry = null;
		// 		zipContent.forEach((relativePath, zipEntry) => {
		// 			var entryNameSplit = zipEntry.name.split("/");
		// 			var entryName = entryNameSplit[entryNameSplit.length - 1];
		// 			if (entryName == inputFilename) {
		// 				foundZipEntry = zipEntry;
		// 			}
		// 		});

		// 		if (foundZipEntry == null) {
		// 			adaptedFilesData.push({
		// 				id: rowId,
		// 				inputFilename: inputFilename,
		// 				workerName: "",
		// 				workerId: null,
		// 				outputFilename: "",
		// 				status: this.workerStates.ERROR,
		// 				comment: this.lviews.fileNotFoundInZip,
		// 				action: "",
		// 				deleted: false,
		// 				visible: true,
		// 				documentType: null,
		// 			});
		// 		}
		// 		else {
		// 			// file is found in excel
		// 			let validatedEntryData = await this.validateEntry(foundZipEntry, excelFile, rowId);                 
		// 			// if row > 1 and row <= 100 then check return status
		// 			if (rowId > 0 && rowId <= 100) {
		// 				if (validatedEntryData.status == this.workerStates.OK || validatedEntryData.status == this.workerStates.WARNING) {
		// 					breakFor = false;
		// 				}                       
		// 			}

		// 			adaptedFilesData.push(
		// 				{
		// 					id: rowId,
		// 					inputFilename: validatedEntryData.inputFilename,
		// 					workerName: validatedEntryData.worker,
		// 					workerId: validatedEntryData.workerId,
		// 					outputFilename: validatedEntryData.outputFilename,
		// 					status: validatedEntryData.status,
		// 					comment: validatedEntryData.comment,
		// 					action: "",
		// 					deleted: false,
		// 					visible: true,
		// 					documentType: validatedEntryData.documentType,
		// 				}
		// 			);
		// 		}

		// 		if (rowId == 100 && breakFor == true) {
		// 			this.showRowErrorImport = true;
		// 			return adaptedFilesData;
		// 		}

		// 		rowId++;
		// 	}
		// 	return adaptedFilesData;
		// },

		verifyExcelFile: async function(file){
			let response
			let formData = new FormData();
			formData.append('file', file)
			formData.append('ignore_headings', this.attachmentOptions.omitHeaders.value)
			try {
					response = await axios({
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/import-verifier/",
						method: "POST",
						headers: {
							'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: formData,
					});
				} catch (error) {
					if (error.message == 'Network Error') {
						this.$emit('set-state', "ERROR", this.lviews.networkError);
					} else {
						switch(error.response.status){
							case 401:
								// Redirect to login page
								this.$router.push("/");
								return;
							case 403:
								this.$emit('set-state', "ERROR", this.lviews.lackOfPermission);
								break;
							case 400:
								this.$emit('set-state', "ERROR", this.lviews.couldNotProcessAllSentFiles);
								break;
							default:
								this.$emit('set-state', "ERROR");
								break;
						}
					}
					
					return {
						contentValid: false,
						errorMessage: error.toString(),
					};
				}

				if(response){
					let adaptedWorkersData = this.adaptWorkerData(response.data);

					return {
						contentValid: true,
						validData: adaptedWorkersData,
						nextPageState: this.pageStates.FILE_VALIDATED,
						errorMessage: null
					}
				}
		},

		importWorkers: async function(){
			if(!this.dataToImport){
				return
			}
			this.uploadingProgressBar.uploadProgress = 0;
			this.uploadingProgressBar.indeterminate = true;
			this.uploadingProgressBar.message = this.lviews.uploadBarPreparingRequest;
			this.pageState = this.pageStates.FILES_UPLOADING;
					let responsePromise = axios({
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/import/",
						method: "POST",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: this.dataToImport,
						onUploadProgress: (progressEvent) => {
							if (progressEvent.lengthComputable) {
								if (progressEvent.loaded == progressEvent.total){
									this.uploadingProgressBar.uploadProgress = 0;
									this.uploadingProgressBar.indeterminate = true;
									this.uploadingProgressBar.message = this.lviews.uploadBarProcessing;
								}
								else{
									let progress = (progressEvent.loaded * 100) / progressEvent.total;
									this.uploadingProgressBar.uploadProgress = progress;
								}
							}
						}
					});
			try {
				let response = await responsePromise;
				this.uploadingProgressBar.uploadProgress = 100;
				this.uploadingProgressBar.indeterminate = false;
				this.uploadingProgressBar.message =  this.lviews.uploadBarComplete;
				this.$emit("set-state", "DEFAULT");

				if(response) {
						this.pageMessages.FILES_UPLOADED.globalMessage = this.lviews.workersUploadedCorrectly.replace("{0}", response.data.length);
						//this.pageMessages.FILES_UPLOADED.globalMessage = this.lviews.workersUploadedCorrectly;
						this.pageState = this.pageStates.WORKERS_UPLOADED_CORRECTLY;
				}
				
			} catch (error) {
					console.log(error.response)
				this.$emit('set-state', "DEFAULT");
					if (error.message == 'Network Error') {
						this.$emit('set-state', "ERROR", this.lviews.networkError);
					} else {
						switch(error.response.status){
							case 401:
								// Redirect to login page
								this.$router.push("/");
								return;
							case 403:
								this.$emit('set-state', "ERROR", this.lviews.lackOfPermission);
								break;
							case 400:
								// this.$emit('set-state', "ERROR", this.lviews.couldNotProcessAllSentFiles);
								break;
							default:
								this.$emit('set-state', "ERROR");
								break;
						}
					}
				}
		},

		/**
		 * Updates datatable content when zip is uploaded
		 * @param input - "change" event of <input type="file"/>
		 */
		updateDataTable: async function(input){
			this.$emit("set-state", "LOADING_OVERLAYER", this.pageMessages.VALIDATION.overlayerMessage);
			let excelFile
			if(input.target != undefined){
				excelFile = input.target.files[0];
			}
			else{
				excelFile = input;
			}
			if (excelFile.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
				this.$emit('set-state', "DEFAULT");
				this.pageState = this.pageStates.INVALID_INPUT_FILE;
				return;
			}

			let zipSizeInMB = excelFile.size / 1048576;
			if (zipSizeInMB > 50){
				this.pageZipErrorMessage = this.pageMessages.VALIDATION.tooLargeExcelLoadedMessage;
				this.pageState = this.pageStates.VALIDATION_ERROR;
				this.$emit("set-state", "DEFAULT");
				return;
			}

			const validationResults = await this.verifyExcelFile(excelFile)

			this.uploadedExcelName = excelFile.name;

			if (validationResults && validationResults.contentValid){
				this.validatedWorkersData = validationResults.validData;
				this.assignRenderedWorkersData();

				this.importSummary.importWorkersNumber = 0;
				this.importSummary.excludedWorkersNumber = 0;
				this.importSummary.errorWorkersNumber = 0;
				this.importSummary.warningDocumentsNumber = 0;

				for (let idx = 0; idx < this.validatedWorkersData.length; idx++) {
					if (this.validatedWorkersData[idx].importStatus == this.workerStates.ERROR) {
						this.importSummary.errorWorkersNumber++;
					}
					// else if (this.validatedWorkersData[idx].status == this.workerStates.WARNING) {
					// 	this.importSummary.warningDocumentsNumber++;
					// 	this.importSummary.importWorkersNumber++;
					// }
					else if (this.validatedWorkersData[idx].importStatus == this.workerStates.OK) {
						this.importSummary.importWorkersNumber++;
					}
				}

				this.uploadedExcelContentSize = this.importSummary.importWorkersNumber; //this.validatedWorkersData.length;

				this.pageMessages.FILE_VALIDATED.workersToAdd.number = this.uploadedExcelContentSize;
				if (this.uploadedExcelContentSize > 1 || this.uploadedExcelContentSize == 0){
					this.pageMessages.FILE_VALIDATED.workersToAdd.suffix = this.lviews.validatedZip.workersToAdd.suffixPlural;
				}
				else{
					this.pageMessages.FILE_VALIDATED.workersToAdd.suffix = this.lviews.validatedZip.workersToAdd.suffixSingular;
				}

				var errorsNumber = this.validatedWorkersData.filter(function(item){
					return item.status == 2;
				}).length;

				if (errorsNumber){
					this.errorInDataOccurred = true;
				} else {
					this.errorInDataOccurred = false;
				}
				this.$emit("set-state", "DEFAULT");
			}
			else {
				this.pageZipErrorMessage = validationResults.errorMessage;
				this.$emit("set-state", "DEFAULT");
			}
			this.pageState = validationResults.nextPageState;           
		},

		/*
		 *  Removes worker from imported workers list
		 *  @param id - imported worker id
		 */
		updateImportConcreteWorker: function(id){
			if (this.validatedWorkersData[id - 1].deleted){
				this.pageMessages.FILE_VALIDATED.workersToAdd.number++;
				this.importSummary.importWorkersNumber++;
				this.importSummary.excludedWorkersNumber--;
			}
			else{
				this.importSummary.importWorkersNumber--;
				this.importSummary.excludedWorkersNumber++;
				this.pageMessages.FILE_VALIDATED.workersToAdd.number--;
			}
			this.validatedWorkersData[id - 1].deleted = !this.validatedWorkersData[id - 1].deleted;
			this.assignRenderedWorkersData();
		},

		// custom-filter method for datatables component
		filterItems: function(value, search, item){
			return  value != null &&
					search != null &&
					item != null && 
					typeof value === 'string' &&
					(
						item.firstName && item.firstName.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.lastName && item.lastName.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.emai && item.email.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.projects && item.projects.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.externalID && item.externalID.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.cards && item.cards.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1
					);
		},

		// Decides what class should be assigned for concrete datatable's item
		getDataTableRowClass: function(dataTableItem){
			if (dataTableItem.status == this.workerStates.ERROR)
				return "row-deleted-background";
			return "";
		},

		// clears validated workers data and goes back to BEFORE_UPLOAD view state
		cancel: function(){
			/*this.attachmentOptions.omitHeaders.value = false;
			this.attachmentOptions.uploadWithDocumentType.value = true;
			this.attachmentOptions.overrideExistingFiles.value = false;*/

			this.validatedWorkersData = [];
			this.renderedWorkersData = [];
			this.pageState = this.pageStates.BEFORE_UPLOAD;
		},

		// determineFileMimeType: async function(fileBlob) {
		// 	return await new Promise((resolve) => {
		// 		let fileReader = new FileReader();

		// 		fileReader.onloadend = function (evt) {
		// 			if (evt.target.readyState === FileReader.DONE) {
		// 				var uint = new Uint8Array(evt.target.result);
		// 				let bytes = [];

		// 				uint.forEach((byte) => {
		// 					bytes.push(byte.toString(16))
		// 				})

		// 				const signature_hex = bytes.join('').toUpperCase();
		// 				let mimeType = "";
		// 				switch (signature_hex) {
		// 					case '89504E47':
		// 						mimeType = 'image/png';
		// 						break;
		// 					case '47494638':
		// 						mimeType = 'image/gif';
		// 						break;
		// 					case '25504446':
		// 						mimeType = 'application/pdf';
		// 						break;
		// 					case 'FFD8FFDB':
		// 					case 'FFD8FFE0':
		// 					case 'FFD8FFE1':
		// 						mimeType = 'image/jpeg';
		// 						break;
		// 					case '504B0304':
		// 						mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		// 						//mimeType = 'application/zip';
		// 						break;
		// 					case '504B34':
		// 						mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		// 						break;
		// 					default:
		// 						mimeType = "";
		// 						break;
		// 				}

		// 				resolve(mimeType);
		// 			}
		// 		}

		// 		fileReader.readAsArrayBuffer(fileBlob.slice(0, 4));
		// 	});
		// },

		// assigns messages for FILE_VALIDATED view state
		assignZipValidatedLangData: function(){
			this.pageMessages.FILE_VALIDATED.uploadedZip = this.lviews.validatedZip.uploadedZip;
			this.pageMessages.FILE_VALIDATED.workersToAdd.label = this.lviews.validatedZip.workersToAdd.label;
			this.pageMessages.FILE_VALIDATED.filtersHeader = this.lviews.validatedZip.filtersHeader;
			this.pageMessages.FILE_VALIDATED.selectFilesStatuses.label = this.lviews.validatedZip.selectFilesStatuses.label;
			this.pageMessages.FILE_VALIDATED.selectFilesStatuses.items = this.lviews.validatedZip.selectFilesStatuses.items;
		
			this.validatedWorkersDataHeaders[0].text = this.lviews.validatedZip.tableHeaders.id;
			this.validatedWorkersDataHeaders[1].text = this.lviews.validatedZip.tableHeaders.lastName;
			this.validatedWorkersDataHeaders[2].text = this.lviews.validatedZip.tableHeaders.firstName;
			this.validatedWorkersDataHeaders[3].text = this.lviews.validatedZip.tableHeaders.externalID;
			this.validatedWorkersDataHeaders[4].text = this.lviews.validatedZip.tableHeaders.email;
			this.validatedWorkersDataHeaders[5].text = this.lviews.validatedZip.tableHeaders.status;
			this.validatedWorkersDataHeaders[6].text = this.lviews.validatedZip.tableHeaders.projects;
			this.validatedWorkersDataHeaders[7].text = this.lviews.validatedZip.tableHeaders.cards;
			this.validatedWorkersDataHeaders[8].text = this.lviews.validatedZip.tableHeaders.tags;
			this.validatedWorkersDataHeaders[9].text = this.lviews.validatedZip.tableHeaders.importStatus;
			this.validatedWorkersDataHeaders[10].text = this.lviews.validatedZip.tableHeaders.importComment;
			this.validatedWorkersDataHeaders[11].text = this.lviews.validatedZip.tableHeaders.action;
		
			this.pageMessages.FILE_VALIDATED.cancelButtonLabel = this.lviews.validatedZip.cancelHeader;
			this.pageMessages.FILE_VALIDATED.addWorkersButtonLabel = this.lviews.validatedZip.addWorkersButtonLabel;
			this.pageMessages.FILE_VALIDATED.uploadWithoutErrorsMessage = this.lviews.validatedZip.uploadWithoutErrorsMessage;
			this.pageMessages.FILE_VALIDATED.goBack = this.lviews.validatedZip.goBack;
			this.pageMessages.FILE_VALIDATED.search = this.lviews.validatedZip.search;

			this.pageMessages.FILE_VALIDATED.status = this.lviews.validatedZip.selectFilesStatuses.label;
			this.workerStatesSelect[0].text = this.lviews.validatedZip.selectFilesStatuses.ok;
			this.workerStatesSelect[1].text = this.lviews.validatedZip.selectFilesStatuses.warning;
			this.workerStatesSelect[2].text = this.lviews.validatedZip.selectFilesStatuses.error;
		},

		// assigns messages for VALIDATION and VALIDATION_ERROR views states
		assignZipValidationLangData: function(){
			this.pageZipErrorMessageHeader = this.lviews.validation.pageZipErrorMessageHeader;
			this.pageMessages.VALIDATION.overlayerMessage = this.lviews.validation.overlayerMessage;
			this.pageMessages.VALIDATION.excelLackMessage = this.lviews.validation.excelLackMessage;
			this.pageMessages.VALIDATION.tooLargeExcelLoadedMessage = this.lviews.validation.tooLargeExcelLoadedMessage;
			this.pageMessages.VALIDATION.invalidZipStructure = this.lviews.validation.invalidZipStructure;
			this.pageMessages.VALIDATION.correctFiles = this.lviews.validation.correctFiles;
			this.pageMessages.VALIDATION.goBack = this.lviews.validation.goBack;
		},
	},
	computed: {
		lviews: {
			get: function(){
				return this.$t('views.importWorkers');
			}
		},
		lmessages: {
			get: function() {
				return this.$t('commons.messages');
			}
		}
	},
	mounted: async function(){
		this.$emit('set-title', this.lviews.title);
		this.$emit('getGoBackLink', "/workers");
		this.pageState = this.pageStates.BEFORE_UPLOAD;

		this.$emit('set-display', "BACK_WINDOW"); 

		this.pageMessages.BEFORE_UPLOAD = this.lviews.beforeUpload;
		this.assignZipValidatedLangData();
		this.assignZipValidationLangData();
		this.pageMessages.INVALID_INPUT_FILE.globalMessage = this.lviews.invalidInputFileGlobalMessage;

		this.attachmentOptions.omitHeaders.label = this.lviews.omitHeadersOption;

		// checkbox
		if (localStorage.getItem("omitHeadersCheckbox") == null || localStorage.getItem("omitHeadersCheckbox") == 0) {
			this.attachmentOptions.omitHeaders.value = false;
		}
		else {
			this.attachmentOptions.omitHeaders.value = true;
		}
 

		if (localStorage.getItem("uploadWithDocumentTypeCheckbox") == null || localStorage.getItem("uploadWithDocumentTypeCheckbox") == 0) {
			this.attachmentOptions.uploadWithDocumentType.value = false;
		}
		else {
			this.attachmentOptions.uploadWithDocumentType.value = true;
		}
		
		if (localStorage.getItem("overrideExistingFilesCheckbox") == null || localStorage.getItem("overrideExistingFilesCheckbox") == 0) {
			this.attachmentOptions.overrideExistingFiles.value = false;
		}
		else {
			this.attachmentOptions.overrideExistingFiles.value = true;
		} 
	},
}
</script>

<style lang="css">
.import-workers-page-container{
	height: 100%;
	width: auto;
	/* padding-top: 35px; */
	padding-left: 10px;
	padding-right: 10px;
}

.attach-files-container{
	width: 100% !important;
	text-align: center;
}

.attach-files-input{
	background-color: #F5F5F6 !important;
	max-height: 550px;
	padding-bottom: 20px;
	border-radius: 10px;
	border: dashed 2px silver;
}

.attach-files-input-header{
	font-size: 20px;
	font-weight: 450;
}

.attachments-process-option{
	font-size: 10px !important;
	margin-right: 10px;
	margin-top: 0px !important;
	margin-left: 15px;
}

.attachments-process-option-select{
	font-size: 15px !important;
	margin-right: 10px;
	margin-top: 0px !important;
	margin-left: 15px;
	max-width: 200px !important;
}

.zip-validated-page{
	background-color: #F5F5F6 !important;
	width: 100%;
	border-radius: 10px;
	border: dashed 2px silver;
	margin-bottom: 15px;
}

.zip-validated-page-header{
	font-size: 16px;
	font-weight: 450;
	padding-top: 7px;
	padding-bottom: 2px !important;
}

.zip-validated-page-header-part{
	width: 50%;
	float: left;
	padding-bottom: 0px !important;
}

.imported-workers-table{
	width: auto;
	margin-left: 15px;
	margin-right: 15px; 
	margin-bottom: 15px;
	background-color: white;
}

.imported-workers-table-sorters{
	background-color: white;
	border-bottom: lightgray 2px solid;
	height: 35px;
	width: 100% !important;
}

.zip-validated-header{
	float: left;

}

.zip-validated-page-footer{
	font-size: 16px;
	font-weight: 450;
	padding-top: 15px;
	padding-bottom: 30px !important;
	text-align: center;
}

.row-deleted{
	text-decoration: line-through;
}

.row-to-import{
	text-decoration: none;
}

.row-deleted-background{
	/*background-color: gray;*/
}

.row-deleted-background:hover{
	/*background-color: gray;*/
}

.back-button-files-uploaded {
	height: 30px !important;
}
</style>